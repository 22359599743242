import React, { createRef } from 'react';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Card } from '@fidize/ui.card';
import { Hr } from '@fidize/ui.hr';
import { Button } from '@fidize/ui.button';
import styles from './cardcolor.module.scss';

export class Signature extends React.Component {
    state = {
        signature: "",
        flipped: false
    }
    card = null;

    constructor(props){
        super(props);
        this.state.signature = window.signature;
    }

    componentDidMount(){
        this.flipCard()
    }

    setSignature = (e) => {
        var newSign = e.target.value
        this.setState({signature: newSign})
        window.signature = newSign;
    }

    flipCard = () => {
        this.setState({flipped: !this.state.flipped})
    }

    render(){
        var self = this;
        var desk =  <Hpadding padding="mobile0">
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black">Inserisci la tua firma</Text>
                            </Flex>

                            <Vspace desk="50px" mobile="30px" />

                            <Flex>
                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card ref={(el) => {if(self.card == null){self.card = el;}}} logo={window.logo} logoSize={window.logoSize} faceColor={window.cardColor} paintColor={window.cardPaint} signature={this.state.signature} code={true} template={window.template} color={window.color} flipped={this.state.flipped} />
                                    </Flex>
                                    <Vspace desk="10px" mobile="10px" />
                                    <Flex type="justify_center">
                                        <Button bkg="grey" onClick={() => {this.flipCard()}}><Text type="normal white">Ruota Card</Text></Button>
                                    </Flex>
                                </div>

                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="column">
                                        <Text type="h4 black">
                                            Firma
                                        </Text>
                                        <Vspace desk="30px" mobile="10px" />
                                        <input type="text" value={this.state.signature} className={styles.input} onChange={this.setSignature}></input>
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </Hpadding>
        var mobile = <Hpadding padding="mobile0">
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black">Inserisci la tua firma</Text>
                            </Flex>

                            <Vspace desk="50px" mobile="10px" />

                            <Flex type="column">
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card ref={(el) => {if(self.card == null){self.card = el;}}} logo={window.logo} logoSize={window.logoSize} faceColor={window.cardColor} paintColor={window.cardPaint} signature={this.state.signature} template={window.template} color={window.color} code={true} flipped={this.state.flipped} />
                                    </Flex>
                                    <Vspace desk="10px" mobile="10px" />
                                    <Flex type="justify_center">
                                        <Button bkg="grey" onClick={() => {this.flipCard()}}><Text type="normal white">Ruota Card</Text></Button>
                                    </Flex>
                                </div>
                                <Vspace desk="50px" mobile="40px" />
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="column">
                                        <Text type="h4 black">
                                            Firma
                                        </Text>
                                        <Vspace desk="30px" mobile="10px" />
                                        <input type="text" value={this.state.signature} className={styles.input} onChange={this.setSignature}></input>
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </Hpadding>

        return (<Responsive desk={desk} mobile={mobile} />);
    }
}
