import React, { createRef } from 'react';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Card } from '@fidize/ui.card';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Button } from '@fidize/ui.button';
import { MdAddAPhoto } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { VscError } from "react-icons/vsc";
import Slider from '@mui/material/Slider';
import styles from './cardcolor.module.scss';

export class Logo extends React.Component {
    state = {
        logo: "",
        logoSize: "",
        state: 0, // 0 idle 1 loading 2 loaded
        percentage: 0,
        src: ""
    }
    card = null;
    cardMobile = null;
    imgInput = null;
    dimensions = [
      {
        value: 0,
        class: "S",
        label: 'Small',
      },
      {
        value: 33,
        class: "M",
        label: 'Medium',
      },
      {
        value: 66,
        class: "L",
        label: 'Large',
      },
      {
        value: 100,
        class: "Full",
        label: 'Full size',
      },
    ];

    constructor(props){
        super(props);
        this.state.logo = window.logo;
        this.state.logoSize = window.logoSize;
        if(window.hasOwnProperty("logoSrc") & window.logoSrc != "" & window.logoSrc != undefined){
          this.state.state = 2;
        }
    }

    selectImage = () => {
        this.imgInput.click();
    }

    fileSelected = () => {
        this.setState({state: 1});
        this.startUpload();
    }

    startUpload = () => {
        const axios = require('axios');
        var formData = new FormData();
        formData.append("img", this.imgInput.files[0]);
        formData.append("type", this.props.type);
        formData.append("position", this.props.position);
        formData.append("card", this.props.card);
        formData.append("code", this.props.code);

        axios.post('/api/uploadlogo', formData, {
            onUploadProgress: (progress) => {
                var perc = (progress.loaded/progress.total * 100).toFixed(0);
                this.setState({percentage: perc});
            }
        }).then((response) => {
            if(response.status == 200){
                this.setState({state: 2});
            }

            this.setLogo(response.data.filename);
        }).catch((error) => {
            this.setState({state: 3});
            //window.toastError("Errore nell'invio. Le immagini possono avere una dimensione massima di 10MB.");
        });
    }

    setLogo = (code) => {
        window.logoSrc = "https://www.fidize.com/logo/" + code;
        //this.setState({logo: "https://www.fidize.com/logo/" + code})
    }

    flipCard = () => {
        this.card.flip()
        this.cardMobile.flip()
    }

    handleChangeSize = (e) => {
      var size = e.target.value;
      var logoSize = this.dimensions.reduce((tot, v) => {
        if(tot != ""){
          return tot;
        }
        if(v.value == size){
          return v.class;
        }
        return tot;
      }, "");
      this.setState({logoSize: logoSize})
      window.logoSize = logoSize
    }

    getSizeValue = (logoSize) => {
      var value = this.dimensions.reduce((tot, v) => {
        if(tot != ""){
          return tot;
        }
        if(v.class == logoSize){
          return v.value;
        }
        return tot;
      }, "");
      return value;
    }

    render(){
        var self = this;
        var innerButton = <Text type="darkgrey h1"><MdAddAPhoto /></Text>;
        var style = {};
        if(this.state.state == 1){
            innerButton = <Flex type="justify_center"><Flex type="column justify_center"><div style={{width: "50px"}}><CircularProgressbar value={this.state.percentage}/></div></Flex></Flex>;
        }
        if(this.state.state == 2){
            innerButton = <Text type="h1" style={{color: "#35db6e"}}><TiTick className="upload-confirm" /></Text>;
            style.backgroundImage = "url('" + window.logoSrc + "')";
        }
        if(this.state.state == 3){
            innerButton = <Text type="darkgrey h1"><VscError className="upload-error" /></Text>;
        }

        var desk =  <Hpadding padding="mobile0">
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black" style={{textAlign: "center"}}>Inserisci il tuo logo</Text>
                            </Flex>
                            <Vspace desk="50px" mobile="30px" />

                            <Flex>
                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card ref={(el) => {if(self.card == null){self.card = el;}}} logo={this.state.logo} logoSize={this.state.logoSize} faceColor={window.cardColor} paintColor={window.cardPaint} signature={window.signature} template={window.template} color={window.color} code={true} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                </div>

                                <div style={{width: "50%", padding: "15px"}}>
                                  {
                                    window.template == 0 ?
                                      <>
                                        <Text type="h4 balck">Scegli la grandezza del logo</Text>
                                        <Vspace desk="15px" mobile="10px" />
                                        <Flex type="justify_center">
                                          <div className={styles['slider']}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={this.getSizeValue(window.logoSize)}
                                              marks={this.dimensions}
                                              step={null}
                                              valueLabelDisplay="off"
                                              onChange={this.handleChangeSize}
                                              />
                                          </div>
                                        </Flex>
                                        <Vspace desk="25px" mobile="20px" />
                                      </> : null
                                  }

                                    <Flex type="column">
                                        <Text type="h4 balck">
                                            Carica il tuo logo
                                            <br></br>
                                            <br></br>
                                            Per un risultato ottimale,
                                            <br></br>
                                            consigliamo di allegare un'immagine
                                            <br></br>
                                            con risoluzione minima di 1000x1000
                                            <br></br>
                                            in formato png con fondo trasparente.
                                            <br></br>
                                            <br></br>
                                            Il tuo logo verrà stampato
                                            <br></br>
                                            al posto della scritta
                                            <br></br>
                                            "Your logo here".
                                        </Text>
                                        <Vspace desk="30px" mobile="10px" />
                                        <Flex type="justify_center">
                                            <button className={styles["buttonUpload"]} onClick={this.selectImage} style={style}>
                                                <input type="file" name="img" id="imgupload" accept="image/*,*/pdf" style={{display:"none"}} ref={(el) => {if(this.imgInput == null)this.imgInput = el}} onChange={this.fileSelected}/>
                                                {
                                                    innerButton
                                                }
                                            </button>
                                        </Flex>
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </Hpadding>
        var mobile = <div>
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black" style={{textAlign: "center"}}>Inserisci il tuo logo</Text>
                            </Flex>
                            <Vspace desk="50px" mobile="10px" />

                            <Flex type="column">
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card ref={(el) => {if(self.cardMobile == null){self.cardMobile = el;}}} logo={this.state.logo} logoSize={this.state.logoSize} faceColor={window.cardColor} paintColor={window.cardPaint} signature={window.signature} template={window.template} color={window.color} code={true} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                    {
                                      window.template == 0 ?
                                        <>
                                          <Text type="h4 balck">Scegli la grandezza del logo</Text>
                                          <Vspace desk="15px" mobile="10px" />
                                          <Flex type="justify_center">
                                            <div className={styles['slider']}>
                                              <Slider
                                                aria-label="Custom marks"
                                                defaultValue={this.getSizeValue(window.logoSize)}
                                                marks={this.dimensions}
                                                step={null}
                                                valueLabelDisplay="off"
                                                onChange={this.handleChangeSize}
                                                />
                                            </div>
                                          </Flex>
                                          <Vspace desk="25px" mobile="20px" />
                                        </> : null
                                    }

                                </div>
                                <Vspace desk="50px" mobile="40px" />
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="column">
                                        <Text type="h4 balck">
                                            Carica il tuo logo
                                            <br></br>
                                            <br></br>
                                            Per un risultato ottimale,
                                            <br></br>
                                            consigliamo di allegare un'immagine
                                            <br></br>
                                            con risoluzione minima di 1000x1000
                                            <br></br>
                                            in formato png con fondo trasparente.
                                            <br></br>
                                            <br></br>
                                            Il tuo logo verrà stampato
                                            <br></br>
                                            al posto della scritta
                                            <br></br>
                                            "Your logo here".
                                        </Text>
                                        <Vspace desk="30px" mobile="30px" />
                                        <Flex type="justify_center">
                                            <button className={styles["buttonUpload"]} onClick={this.selectImage} style={style}>
                                                <input type="file" name="img" id="imgupload" accept="image/*" style={{display:"none"}} ref={(el) => {if(this.imgInput == null)this.imgInput = el}} onChange={this.fileSelected}/>
                                                {
                                                    innerButton
                                                }
                                            </button>
                                        </Flex>
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </div>

        return (<Responsive desk={desk} mobile={mobile} />);
    }
}
