import * as React from "react"
import { Text } from '@fidize/ui.text';
import { Flex } from '@fidize/ui.flex';
import { Vspace } from '@fidize/ui.vspace';
import { Button } from '@fidize/ui.button';
import { Link } from 'gatsby'
import { MdReportGmailerrorred } from 'react-icons/md';
import { Configurator } from "../components/configurator";

const ConfiguratorPage = (data) => {
  if(data.location.search.indexOf('?') != 0){
    return (
      <Flex type="column">
        NO ID
      </Flex>
    )
  }

  var orderid = data.location.search.split('?')[1];
  return (
    <>
      <head>
        <title>Fidize - Configura la tua carta</title>
      </head>
      <Configurator orderId={orderid} />
    </>
  )
}

export default ConfiguratorPage
