import React, { createRef } from 'react';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Card } from '@fidize/ui.card';
import { Hr } from '@fidize/ui.hr';
import { Button } from '@fidize/ui.button';
import styles from './cardcolor.module.scss';
import cratio from './static/cratio.png';

export class CardColor extends React.Component {
    state = {
        color: "black"
    }
    card = null;
    cardMobile = null;

    constructor(props){
        super(props);
        this.state.color = window.cardColor;
    }

    reloading = false
    goToCongifurator = () => {
        if(!this.reloading){
            window.location.reload();
            this.reloading = true;
        }
    }

    goToTop = () => {
        window.scroll(0,0);
    }

    componentDidMount(){
        document.getElementsByClassName("prev-btn")[0].disabled = "false";
        document.getElementsByClassName("prev-btn")[0].onclick = this.goToCongifurator
        document.getElementsByClassName("prev-btn")[0].classList.remove("_2RWmX");

        document.getElementsByClassName("prev-btn")[1].disabled = "false";
        document.getElementsByClassName("prev-btn")[1].onclick = this.goToCongifurator
        document.getElementsByClassName("prev-btn")[1].classList.remove("_2RWmX");

        document.getElementsByClassName("next-btn")[0].onclick = this.goToTop
        document.getElementsByClassName("next-btn")[1].onclick = this.goToTop
    }

    componentWillUnmount(){
        document.getElementsByClassName("prev-btn")[0].onclick = () => {}
        document.getElementsByClassName("prev-btn")[1].onclick = () => {}
    }

    setColor = (color) => {
        this.setState({color: color})
        window.cardColor = color;
    }

    flipCard = () => {
        this.card.flip()
        this.cardMobile.flip();
    }

    render(){
        var self = this;
        var desk =  <div>
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black" style={{textAlign: "center"}}>Seleziona il colore della Card</Text>
                            </Flex>
                            <Vspace desk="50px" mobile="30px" />

                            <Flex>
                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card template={0} ref={(el) => {if(self.card == null){self.card = el;}}} logo="" faceColor={this.state.color} paintColor={window.cardPaint == "colored" ? "black" : window.cardPaint} signature={window.signature} code={true} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                </div>
                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="column">
                                        <Text type="h4 black">Colore</Text>
                                        <Vspace desk="5px" mobile="5px" />

                                        <Flex>
                                            <Flex type="column" style={{width: "50%", paddingRight: "5px", boxSizing: "border-box"}}>
                                                <div className={styles['colorDiv']} onClick={() => {this.setColor("black")}} style={{backgroundColor: "#1d1d1d", borderColor: (this.state.color == "black" ? "#37a5ff" : "#B8B8B855")}}>
                                                    <img src={cratio} className={styles['cardRatio']} />
                                                </div>
                                                <Vspace desk="10px" mobile="10px" />
                                                <Flex type="justify_center">
                                                  <Text type="black xs bold">Obsidian Black</Text>
                                                </Flex>
                                                <Vspace desk="15px" mobile="15px" />
                                            </Flex>

                                            <Flex type="column" style={{width: "50%", paddingLeft: "5px", boxSizing: "border-box"}}>
                                                <div className={styles['colorDiv']} onClick={() => {this.setColor("white")}} style={{backgroundColor: "#FaFaFa", borderColor: (this.state.color == "white" ? "#37a5ff" : "#B8B8B855")}}>
                                                  <img src={cratio} className={styles['cardRatio']} />
                                                </div>
                                                <Vspace desk="10px" mobile="10px" />
                                                <Flex type="justify_center">
                                                  <Text type="black xs bold">Star White</Text>
                                                </Flex>
                                                <Vspace desk="15px" mobile="15px" />
                                            </Flex>
                                        </Flex>
                                            <Flex type="column" style={{width: "50%", paddingTop: "10px", paddingRight: "5px", boxSizing: "border-box"}}>
                                                <div className={styles['colorDiv']} onClick={() => {this.setColor("gradient")}} style={{backgroundImage: "linear-gradient(90deg, #26117C, #A100D4)", borderColor: (this.state.color == "gradient" ? "#37a5ff" : "#B8B8B855")}}>
                                                  <img src={cratio} className={styles['cardRatio']} />
                                                </div>
                                                <Vspace desk="10px" mobile="10px" />
                                                <Flex type="justify_center">
                                                  <Text type="black xs bold">Magic Purple</Text>
                                                </Flex>
                                                <Vspace desk="15px" mobile="15px" />
                                            </Flex>
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </div>
        var mobile = <div>
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black" style={{textAlign: "center"}}>Seleziona il colore della Card</Text>
                            </Flex>
                            <Vspace desk="50px" mobile="10px" />

                            <Flex type="column">
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card template={0} ref={(el) => {if(self.cardMobile == null){self.cardMobile = el;}}} logo="" faceColor={this.state.color} paintColor={window.cardPaint == "colored" ? "black" : window.cardPaint} signature={window.signature} code={true} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                </div>
                                <Vspace desk="50px" mobile="40px" />
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="column">
                                        <Text type="h4 black">Colore</Text>
                                        <Vspace desk="5px" mobile="5px" />

                                        <Flex>
                                            <Flex type="column" style={{width: "50%", paddingRight: "5px", boxSizing: "border-box"}}>
                                                <div className={styles['colorDiv']} onClick={() => {this.setColor("black")}} style={{backgroundColor: "#1d1d1d", borderColor: (this.state.color == "black" ? "#37a5ff" : "#B8B8B855")}}>
                                                    <img src={cratio} className={styles['cardRatio']} />
                                                </div>
                                                <Vspace desk="10px" mobile="10px" />
                                                <Flex type="justify_center">
                                                  <Text type="black xs bold">Obsidian Black</Text>
                                                </Flex>
                                                <Vspace desk="15px" mobile="15px" />
                                            </Flex>

                                            <Flex type="column" style={{width: "50%", paddingLeft: "5px", boxSizing: "border-box"}}>
                                                <div className={styles['colorDiv']} onClick={() => {this.setColor("white")}} style={{backgroundColor: "#FaFaFa", borderColor: (this.state.color == "white" ? "#37a5ff" : "#B8B8B855")}}>
                                                  <img src={cratio} className={styles['cardRatio']} />
                                                </div>
                                                <Vspace desk="10px" mobile="10px" />
                                                <Flex type="justify_center">
                                                  <Text type="black xs bold">Star White</Text>
                                                </Flex>
                                                <Vspace desk="15px" mobile="15px" />
                                            </Flex>
                                        </Flex>
                                            <Flex type="column" style={{width: "50%", paddingTop: "10px", paddingRight: "5px", boxSizing: "border-box"}}>
                                                <div className={styles['colorDiv']} onClick={() => {this.setColor("gradient")}} style={{backgroundImage: "linear-gradient(90deg, #26117C, #A100D4)", borderColor: (this.state.color == "gradient" ? "#37a5ff" : "#B8B8B855")}}>
                                                  <img src={cratio} className={styles['cardRatio']} />
                                                </div>
                                                <Vspace desk="10px" mobile="10px" />
                                                <Flex type="justify_center">
                                                  <Text type="black xs bold">Magic Purple</Text>
                                                </Flex>
                                                <Vspace desk="15px" mobile="15px" />
                                            </Flex>
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </div>

        return (<Responsive desk={desk} mobile={mobile} />);
    }
}
