import React from 'react';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Vspace } from '@fidize/ui.vspace';
import styles from './template.module.scss';

export class Template extends React.Component {
  state = {
    template: 1,
    printColors: ["white", "black", "gold", "silver"],
    printColor: "black",
    cardColor: "black",
    multicolor: false,
    selected: false
  }
  imgs = {
    black: "",
    white: "",
    silver: "",
    gold: "",
    color: ""
  }

  constructor(props){
    super(props);
    this.init(props);
  }

  init(props){
    this.state.template = props.hasOwnProperty("template") ? props.template : 1;
    this.state.cardColor = props.hasOwnProperty("cardColor") ? props.cardColor : "black";
    // Rimuovo print color white se card white
    if(this.state.cardColor == "white"){
      this.state.printColors = this.state.printColors.reduce((tot, el) => {
        if(el != "white"){
          tot.push(el);
        }
        return tot;
      }, [])
      this.state.multicolor = props.hasOwnProperty("multicolor") ? props.multicolor : false;
      if(this.state.multicolor){
        this.state.printColors = [];
      }
    }
    // Importo i le src dei colori dei template
    var root = "./static/templates/" + this.state.template + "/";
    if(!this.state.multicolor){
      this.imgs.black = require(root + "black.png").default;
      this.imgs.white = require(root + "white.png").default;
      this.imgs.silver = require(root + "silver.png").default;
      this.imgs.gold = require(root + "gold.png").default;
      if(this.props.colored){
        this.imgs.colored = require(root + "colored.png").default;
      }
    }else{
      this.imgs.color = require(root + "color.png").default;
      this.state.printColor = "color";
    }
    this.fixTemplateColor();
    this.state.selected = props.selected == this.state.template ? true : false;
    this.state.printColor = props.selected == this.state.template ? props.color : this.state.printColor;
  }

  fixTemplateColor = () => {
    // Con card nera colore default bianco e viceversa
    if(this.state.printColor != "color"){
      if(this.state.cardColor == "white"){
        this.state.printColor = "black"
      }else{
        this.state.printColor = "white"
      }
    }
  }

  changeColor = (color) => {
    this.setState({printColor: color, selected: true});
    this.props.setTemplate(this.state.template, color);
  }

  shouldComponentUpdate(props){
      this.init(props);
      return true;
  }

  render(){
    var style = this.props.hasOwnProperty("style") ? this.props.style : {};
    return  <div className={styles['template']}>
              <Flex type="column" style={style}>
                <div className={styles['cardDiv'] + " " + styles['card_' + this.state.cardColor] + (this.state.selected ? " " + styles['selected'] : "")} onClick={() => {this.changeColor(this.state.printColor)}}>
                  <img src={this.imgs[this.state.printColor]} className={styles['cardImg']} />
                </div>
                <Vspace desk="10px" mobile="7px" />
                <Flex type="justify_center">
                  {
                    this.state.printColors.map((c) => {
                      var selected = this.state.printColor == c ? true : false;
                      return <button className={styles['colorBtn'] + " " + styles['colorBtn_' + c] + (this.state.selected & selected ? " " + styles['selected'] : "")} onClick={() => {this.changeColor(c)}}></button>
                    })
                  }
                  {
                    this.props.colored ?
                      <button className={styles['colorBtn'] + " " + styles['colorBtn_colored'] + (this.state.selected & this.state.printColor == "colored" ? " " + styles['selected'] : "")} onClick={() => {this.changeColor("colored")}}></button>
                    : <></>
                  }
                  {
                    this.state.printColors.length == 0 ? <Vspace desk="25px" mobile="25px" /> : <></>
                  }
                </Flex>
                <Vspace desk="20px" mobile="15px" />
              </Flex>
            </div>
  }
}
