import React from 'react';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Card } from '@fidize/ui.card';
import { Hr } from '@fidize/ui.hr';
import { Button } from '@fidize/ui.button';
import StepProgressBar from 'react-step-progress';
import { CardColor } from './cardcolor';
import 'react-step-progress/dist/index.css';
import styles from './configurator.module.scss';
import './configurator.css';
import { PrintColor } from './printcolor';
import { Logo } from './logo';
import { Signature } from './signature';
import { Link } from 'gatsby'

export class Configurator extends React.Component {
    state = {
        loading: true,
        cardId: null,
        configured: false,
        confirmed: false,
        cards: []
    }

    componentDidMount(){
        this.fetchConfigs(this.props.orderId)
    }

    getConfiguredState(){
        var isFlase = false;
        var isTrue = false;
        this.state.cards.map((c) => {
            if(c.configured){
                isTrue = true;
            }else{
                isFlase = true;
            }
        })
        if(isFlase & !isTrue){
            return 0
        }
        if(isFlase & isTrue){
            return 1
        }
        return 2;
    }

    fetchConfigs(orderId){
        const axios = require('axios');
        var formData = new FormData();
        formData.append("orderId", orderId);
        axios.post('/api/getNfcConfig', formData, {}).then((response) => {
            if(response.status == 200){
                var configured = response.data.reduce((tot, c) => {
                    if(!tot){
                        return tot
                    }
                    return c.configured;
                }, true)
                var confirmed = response.data.reduce((tot, c) => {
                    if(!tot){
                        return tot
                    }
                    return c.confirmed;
                }, true)
                var loading = false;
                if(response.data.length == 0){
                    configured = confirmed = false;
                    loading = true;
                }
                this.setState({loading: loading, cards: response.data, configured: configured, confirmed: confirmed});
            }
        }).catch((error) => {
            setTimeout(() => {
                this.fetchConfigs(orderId);
            }, 1500);
        });
    }

    selectCard(card){
        window.template = card.template
        window.cardColor = card.cardColor
        window.cardPaint = card.cardPaint
        window.logo = card.logo
        window.logoSrc = card.logoSrc
        window.logoSize = card.logoSize
        window.signature = card.signature
        this.setState({cardId: card.cardId})
    }

    logoValidator = () => {
      var logoSrc = window.logoSrc + "";
      if(logoSrc.length > 0 & logoSrc == "undefined"){
        window.toastError("Per continuare devi allegare il tuo logo.")
        return false;
      }else{
        return true;
      }
    }

    sendData = () => {
        const axios = require('axios');
        var formData = new FormData();
        var sendData = this.sendData;

        formData.append("orderId", this.state.cards[0].orderId);
        formData.append("cardId", this.state.cardId);
        formData.append("cardColor", window.cardColor);
        formData.append("cardPaint", window.cardPaint);
        formData.append("logo", window.logo);
        formData.append("logoSrc", window.logoSrc);
        formData.append("logoSize", window.logoSize);
        formData.append("template", window.template);
        formData.append("signature", window.signature);

        axios.post('/api/saveNfc', formData, {}).then((response) => {
            if(response.status == 200){
                this.setState({state: 2});
            }
            window.toastSuccess("Salvataggio effettuato!");
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }).catch((error) => {
            setTimeout(() => {
                sendData();
            }, 1500)
            //toastError("Quasi fatto! Attendere...");
        });
    }

    sendConfirm = () => {
        const axios = require('axios');
        var formData = new FormData();
        var sendData = this.sendData;

        formData.append("orderId", this.state.cards[0].orderId);

        axios.post('/api/confirmNfcOrder', formData, {}).then((response) => {
            if(response.status == 200){
                this.setState({state: 2});
            }
            window.toastSuccess("Ordine trasmesso!");
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }).catch((error) => {
            setTimeout(() => {
                sendData();
            }, 1500)
            //toastError("Quasi fatto! Attendere...");
        });
    }

    render(){

        var desk = <></>
        var mobile = <></>
        if(this.state.loading){
            desk = <Hpadding padding="1">
                            <Flex type="justify_center">
                                <Text type="h2" style={{padding: "150px 0px"}}>Attendere</Text>
                            </Flex>
                        </Hpadding>
        }else if(this.state.cardId == null){
            var configState = this.getConfiguredState(); // 0 niente configurato, 1 parziale, 2 configurato
            desk =  <Hpadding padding="1">
                        <Vspace desk="50px" mobile="30px" />
                        <Flex type="justify_center">
                            <Text type="h2 black">{this.state.confirmed ? "Ordine trasmesso!" : "Personalizza la tua Card"}</Text>
                        </Flex>
                        <Vspace desk="5px" mobile="5px" />
                        <Flex type="justify_center">
                            {
                                configState == 0 ?
                                <Text type="normal black">Clicca sul tasto "Configura" per iniziare</Text>
                                :
                                configState == 1 ?
                                <Text type="normal black">Clicca sul tasto "Configura" per personalizzare la carta oppure modifica quelle già configurate.</Text>
                                :
                                !this.state.confirmed ?
                                <Flex type="column">
                                    <Vspace desk="5px" mobile="5px" />
                                    <Flex type="justify_center">
                                        <Text type="normal black">Clicca su "Conferma ordine" per finalizzare l'acquisto.</Text>
                                    </Flex>
                                    <Flex type="justify_center">
                                        <Text type="normal black">Se vuoi apportare delle modifiche clicca sul tasto apposito.</Text>
                                    </Flex>
                                </Flex>
                                :
                                <Flex type="column">
                                    <Vspace desk="5px" mobile="5px" />
                                    <Flex type="justify_center">
                                        <Text type="normal black">Grazie per aver completato il processo di personalizzazione.</Text>
                                    </Flex>
                                    <Flex type="justify_center">
                                        <Text type="normal black">L'ordine verrà ora elaborato e spedito al tuo indirizzo.</Text>
                                    </Flex>
                                    <Flex type="justify_center">
                                        <Text type="normal black">Da questo momento non è più possibile effettuare modifiche.</Text>
                                    </Flex>
                                </Flex>
                            }
                        </Flex>

                        <Vspace desk="40px" mobile="30px" />

                        <div className={styles['containerCards']}>
                        {
                            this.state.cards.map((card) => {
                                return  <div className={styles['containerCard']} style={{ margin: (this.state.cards.length == 1 ? "auto" : "unset"), float: (this.state.cards.length == 1 ? "unset" : "left") }}>
                                            <Flex type="column" style={{borderRadius: "8px", backgroundColor: "#F4F4F4", height: "340px"}}>
                                                <Vspace desk="30px" mobile="20px" />
                                                <Flex type="justify_center" style={{transform: "scale(0.8)"}}>
                                                    <Card logo={card.logo} logoSize={card.logoSize} faceColor={card.cardColor} paintColor={card.cardPaint} signature={card.signature} template={card.template} code={true} />
                                                </Flex>
                                                <Vspace desk="20px" mobile="15px" />
                                                <Flex type="justify_center">
                                                    {
                                                        card.configured ?
                                                            !this.state.confirmed ?
                                                            <Flex type="column">
                                                                <Flex type="justify_center">
                                                                    <Text type="xs bold black">✓ Configurata</Text>
                                                                </Flex>
                                                                <Flex type="justify_center" style={{marginTop: "5px"}}>
                                                                    <span onClick={() => {this.selectCard(card)}} style={{cursor: "pointer"}}><Text type="xs bold blue">Clicca qui per modificare</Text></span>
                                                                </Flex>
                                                            </Flex>
                                                            :
                                                            <Flex type="column">
                                                                <Flex type="justify_center">
                                                                    <Text type="xs bold black">✓ Configurata</Text>
                                                                </Flex>
                                                            </Flex>
                                                         :
                                                            <Button onClick={() => {this.selectCard(card)}}><Text type="normal white">Configura</Text></Button>
                                                    }
                                                </Flex>
                                                <Vspace desk="45px" mobile="35px" />
                                            </Flex>
                                        </div>
                            })
                        }
                        </div>
                        {
                            this.state.configured ?
                                !this.state.confirmed ?
                                <Flex type="column">
                                    <Vspace desk="50px" mobile="5px" />
                                    <Flex type="justify_center">
                                        <Text type="normal black">Attenzione!</Text>
                                    </Flex>
                                    <Flex type="justify_center">
                                        <Text type="normal black" style={{textAlign: "center"}}>
                                          Dopo aver cliccato il tasto "Conferma ordine"
                                          <br></br>
                                          non sara più possibile apportare modifiche.
                                          <br></br>
                                          <br></br>
                                          Il tuo logo verrà stampato
                                          <br></br>
                                          al posto della scritta
                                          <br></br>
                                          "Your logo here".
                                        </Text>
                                    </Flex>
                                    <Vspace desk="40px" mobile="20px" />
                                    <Flex type="justify_center">
                                        <Button onClick={() => {this.sendConfirm()}} style={{paddingLeft: "70px", paddingRight: "70px"}}><Text type="normal white">Conferma ordine</Text></Button>
                                    </Flex>
                                </Flex>
                                :
                                <Flex type="justify_center" style={{marginTop: "50px"}}>
                                    <Link to="/"><Text type="normal blue bold" style={{textAlign: "center"}}>Torna al sito {'>'}</Text></Link>
                                </Flex>
                            :
                            <></>
                        }
                        <Vspace desk="100px" mobile="40px" />
                    </Hpadding>

            mobile = <Hpadding padding="mobile0">
            <Vspace desk="50px" mobile="30px" />
            <Flex type="justify_center">
                <Text type="h2 black" style={{textAlign: "center"}}>{!this.state.confirmed ? "Personalizza la tua Card" : "Ordine trasmesso!"}</Text>
            </Flex>
            <Vspace desk="5px" mobile="15px" />
            <Flex type="justify_center">
                {
                    configState == 0 ?
                    <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Clicca sul tasto "Configura" per iniziare</Text>
                    :
                    configState == 1 ?
                    <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Clicca sul tasto "Configura" per personalizzare la carta<br></br>oppure modifica quelle già configurate.</Text>
                    :
                    !this.state.confirmed ?
                    <Flex type="column">
                        <Flex type="justify_center">
                            <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Clicca su "Conferma ordine" per finalizzare l'acquisto.</Text>
                        </Flex>
                        <Flex type="justify_center">
                            <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Se vuoi apportare delle modifiche clicca sul tasto apposito.</Text>
                        </Flex>
                    </Flex>
                    :
                    <Flex type="column">
                        <Vspace desk="5px" mobile="5px" />
                        <Flex type="justify_center">
                            <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Grazie per aver completato il processo di personalizzazione.</Text>
                        </Flex>
                        <Flex type="justify_center">
                            <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>L'ordine verrà ora elaborato e spedito al tuo indirizzo.</Text>
                        </Flex>
                        <Flex type="justify_center">
                            <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Da questo momento non è più possibile effettuare modifiche.</Text>
                        </Flex>
                    </Flex>
                }
            </Flex>

            <Vspace desk="40px" mobile="30px" />

            <div className={styles['containerCards']}>
            {
                this.state.cards.map((card) => {
                    return  <div className={styles['containerCard']} style={{ width: "100%", margin: (this.state.cards.length == 1 ? "auto" : "unset"), float: (this.state.cards.length == 1 ? "unset" : "left") }}>
                                <Flex type="column" style={{borderRadius: "8px", backgroundColor: "#F4F4F4", height: "340px"}}>
                                    <Vspace desk="30px" mobile="20px" />
                                    <Flex type="justify_center" style={{transform: "scale(0.8)"}}>
                                        <Card template={card.template} logo={card.logo}  logoSize={card.logoSize} faceColor={card.cardColor} paintColor={card.cardPaint} signature={card.signature} code={true} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="15px" />
                                    <Flex type="justify_center">
                                        {
                                            card.configured ?
                                                !this.state.confirmed ?
                                                <Flex type="column">
                                                    <Flex type="justify_center">
                                                        <Text type="normal bold darkgrey">✓ Configurata</Text>
                                                    </Flex>
                                                    <Flex type="justify_center" style={{marginTop: "5px"}}>
                                                        <span onClick={() => {this.selectCard(card)}} style={{cursor: "pointer"}}><Text type="normal bold blue">Clicca qui per modificare</Text></span>
                                                    </Flex>
                                                </Flex>
                                                :
                                                <Flex type="column">
                                                    <Flex type="justify_center">
                                                        <Text type="normal bold darkgrey">✓ Configurata</Text>
                                                    </Flex>
                                                </Flex>
                                             :
                                                <Button onClick={() => {this.selectCard(card)}}><Text type="normal bold white">Configura</Text></Button>
                                        }
                                    </Flex>
                                    <Vspace desk="45px" mobile="20px" />
                                </Flex>
                            </div>
                })
            }
            </div>
            {
                this.state.configured ?
                    !this.state.confirmed ?
                        <Flex type="column">
                            <Vspace desk="5px" mobile="50px" />
                            <Flex type="justify_center">
                                <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>Attenzione!</Text>
                            </Flex>
                            <Flex type="justify_center">
                                <Text type="normal bold black" style={{textAlign: "center", fontSize: "16px"}}>
                                  Dopo aver cliccato il tasto "Conferma ordine"
                                  <br></br>
                                  non sara più possibile apportare modifiche.
                                  <br></br>
                                  <br></br>
                                  Il tuo logo verrà stampato
                                  <br></br>
                                  al posto della scritta
                                  <br></br>
                                  "Your logo here".
                                </Text>
                            </Flex>
                            <Vspace desk="30px" mobile="30px" />
                            <Flex type="justify_center">
                                <Button onClick={() => {this.sendConfirm()}} style={{padding: "10px 70px"}}><Text type="h4 white" style={{fontSize: "17px"}}>Conferma ordine</Text></Button>
                            </Flex>
                        </Flex>
                        :
                        <Flex type="justify_center" style={{marginTop: "50px"}}>
                            <Link to="/"><Text type="h4 blue bold" style={{textAlign: "center"}}>Torna al sito {'>'}</Text></Link>
                        </Flex>
                :
                <></>
            }
            <Vspace desk="70px" mobile="70px" />
        </Hpadding>
        }else{
            // Card selezionata
            desk =  <Hpadding padding="0">
                        <div className={styles['fontFamily']}>
                            <StepProgressBar
                                startingStep={0}
                                steps={[
                                    {
                                        label: 'Card',
                                        name: 'step 1',
                                        content: <CardColor card={this.state.cards[this.state.cardId]} />
                                    },
                                    {
                                        label: 'Grafica',
                                        name: 'step 2',
                                        content: <PrintColor card={this.state.cards[this.state.cardId]} />
                                    },
                                    {
                                        label: 'Logo',
                                        name: 'step 3',
                                        content: <Logo card={this.state.cards[this.state.cardId]} />,
                                        validator: this.logoValidator
                                    },
                                    {
                                        label: 'Firma',
                                        name: 'step 4',
                                        content: <Signature card={this.state.cards[this.state.cardId]} />
                                    }
                                ]}
                                labelClass={styles['label']}
                                contentClass={styles["stepcontent"]}
                                primaryBtnClass={styles["buttonPrimary"] + " next-btn"}
                                secondaryBtnClass={styles["buttonPrimary"] + " " + styles["buttonSecondary"] + " prev-btn"}
                                stepClass={styles["step"]}
                                buttonWrapperClass={styles["stepButtonDiv"]}
                                previousBtnName="Indietro"
                                nextBtnName="Avanti"
                                submitBtnName='Salva'
                                onSubmit={this.sendData}
                            />
                        </div>
                    </Hpadding>
                mobile = desk;
        }

        return (<Responsive desk={desk} mobile={mobile} />);
    }
}
