import React, { createRef } from 'react';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Card } from '@fidize/ui.card';
import { Hr } from '@fidize/ui.hr';
import { Button } from '@fidize/ui.button';
import { Template } from './template';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './cardcolor.module.scss';

export class PrintColor extends React.Component {
    state = {
        template: 1,
        color: "black",
        logo: "",
        logoSize: "M",
        flipped: false
    }
    colorTemplates = [9,11,12,13]
    card = null;

    constructor(props){
        super(props);
        this.state.template = window.template;
        this.state.color = window.cardPaint;
        this.state.logo = window.logo;
        this.state.logoSize = window.logoSize;

        this.fixCardTemplate();
    }

    fixCardTemplate = () => {
      // Se il template è incoerente col colore card
      if(this.colorTemplates.indexOf(this.state.template) > -1 || window.cardPaint == "colored"){
        if(window.cardColor != "white"){
          this.state.template = 0;
          window.template = 0;
          this.state.color = "white";
          window.cardPaint = "white";
        }
      }else if(window.cardColor == "white"){
          this.state.template = 0;
          window.template = 0;
          this.state.color = "black";
          window.cardPaint = "black";
      }
    }

    flipCard = () => {
        this.setState({flipped: !this.state.flipped})
    }

    setTemplate = (template, color) => {
      this.setState({template: template, color: color});
      window.template = template;
      window.cardPaint = color;
    }

    render(){
        var self = this;
        var settings = {
          dots: true,
          dotsClass: "slick-dots " + styles['dots'],
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        };

        var templates = <Slider {...settings}><div>
          <Flex>
            <Template template={0} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate} colored={window.cardColor == "white"}/>
            <Template template={1} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
          </Flex>

          <Flex>
            <Template template={2} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
            <Template template={3} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
          </Flex>
        </div>

        <div>
          <Flex>
            <Template template={4} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
            <Template template={5} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
          </Flex>

          <Flex>
            <Template template={6} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
            <Template template={7} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
          </Flex>
        </div>

        <div>
          <Flex>
            <Template template={8} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
            <Template template={10} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
          </Flex>
          <Flex>
            <Template template={14} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
            <Template template={15} cardColor={window.cardColor} color={this.state.color} selected={this.state.template} setTemplate={this.setTemplate}/>
          </Flex>
        </div>

        {
          window.cardColor == "white" ?
            <div>
              <Flex>
                <Template template={9} cardColor={window.cardColor} color={this.state.color} multicolor={true} selected={this.state.template} setTemplate={this.setTemplate}/>
                <Template template={11} cardColor={window.cardColor} color={this.state.color} multicolor={true} selected={this.state.template} setTemplate={this.setTemplate}/>
              </Flex>
              <Flex>
                <Template template={12} cardColor={window.cardColor} color={this.state.color} multicolor={true} selected={this.state.template} setTemplate={this.setTemplate}/>
                <Template template={13} cardColor={window.cardColor} color={this.state.color} multicolor={true} selected={this.state.template} setTemplate={this.setTemplate}/>
              </Flex>
            </div> : null
        }
      </Slider>

        var infoColor = (this.state.color == "colored")
        var desk =  <div>
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black" style={{textAlign: "center"}}>Seleziona la grafica e scegli il colore</Text>
                            </Flex>
                            <Vspace desk="50px" mobile="30px" />

                            <Flex>
                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card ref={(el) => {if(self.card == null){self.card = el;}}} logo={this.state.logo} logoSize={this.state.logoSize} faceColor={window.cardColor} paintColor={this.state.color} template={this.state.template} signature={window.signature} code={true} flipped={this.state.flipped} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                    <Flex type="justify_center">
                                        <Button bkg="grey" onClick={() => {this.flipCard()}}><Text type="normal white">Ruota Card</Text></Button>
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                    <Text type="h4 black" style={{textAlign: "center", width: "100%", opacity: (infoColor ? "1" : "0")}}>Il logo verrà stampato nel colore<br></br>originale dell’immagine da te inviata</Text>
                                </div>
                                <div style={{width: "50%", padding: "15px"}}>
                                    <Flex type="column">
                                        <Text type="h4 black">Grafica</Text>
                                        <Vspace desk="5px" mobile="5px" />

                                        { templates }
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </div>

        var mobile = <div>
                        <Flex type="column">
                            <Flex type="justify_center">
                                <Text type="h2 black" style={{textAlign: "center"}}>Seleziona la grafica e scegli il colore</Text>
                            </Flex>
                            <Vspace desk="50px" mobile="10px" />

                            <Flex type="column">
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="justify_center" style={{transform: "scale(0.75)"}}>
                                        <Card ref={(el) => {if(self.card == null){self.card = el;}}} logo={this.state.logo} logoSize={this.state.logoSize} faceColor={window.cardColor} paintColor={this.state.color} template={this.state.template} signature={window.signature} code={true} flipped={this.state.flipped} />
                                    </Flex>
                                    <Vspace desk="20px" mobile="10px" />
                                    <Flex type="justify_center">
                                        <Button bkg="grey" onClick={() => {this.flipCard()}}><Text type="normal white">Ruota Card</Text></Button>
                                    </Flex>
                                    <Vspace desk="20px" mobile="30px" />
                                    <Text type="h4 black" style={{textAlign: "center", width: "100%", opacity: (infoColor ? "1" : "0")}}>Il logo verrà stampato nel colore<br></br>originale dell’immagine da te inviata</Text>
                                </div>
                                <Vspace desk="50px" mobile="40px" />
                                <div style={{width: "100%", padding: "0px"}}>
                                    <Flex type="column">
                                        <Text type="h4 black">Grafica</Text>
                                        <Vspace desk="5px" mobile="5px" />

                                        { templates }
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </div>

        return (<Responsive desk={desk} mobile={mobile} />);
    }
}
